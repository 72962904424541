import React from "react";

const HomePage = () => {
  return (
    <div className="banner_new home">
      {/* <div className="container home_cont">

      </div> */}
      <div className="banner-col-wrapper">
        <div className="banner-colms banner-right">
          <span className="name-text">Bhagirath Sheela</span>
          <span className="name-intro">Engineer | Traveller | Photography</span>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
