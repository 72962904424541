import React from 'react'
import HomePage from './HomePage';
import Skills from './Skills';
import Education from './Education';

const index = () => {
  return (
    <>
    <HomePage/>
    <Education />
    
    </>
  );
}

export default index